type Name = {
  firstName: string;
  lastName: string;
};

export const titleCase = (text: string): string =>
  text
    .split(' ')
    .filter(Boolean)
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');

export const formatName = ({ firstName, lastName }: Name) => titleCase(`${firstName} ${lastName}`);

/** capitalizeFirstLetter
 * to capitalize the first letter of each word
 */
export const upperFirst = (text?: string) => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Convert Date object to locale formatted date string
export const localeDate = (date: string, options: Intl.DateTimeFormatOptions = {}) => {
  return new Date(date).toLocaleDateString('en-AU', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    ...options
  });
};

// Convert Date object to locale formatted time string
export const localeTime = (date: string, options: Intl.DateTimeFormatOptions = {}) => {
  return new Date(date).toLocaleTimeString('en-AU', {
    timeStyle: 'short',
    ...options
  });
};
