import {
  Button,
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogHeading,
  DialogPrimaryActions,
  DialogSecondaryActions,
  Em,
  FormField,
  Loader,
  P,
  TextInput,
} from '@compono/ui';
import { IConfirmDeleteDialog } from './types';
import { useState } from 'react';

export default function ConfirmDeleteDialog({
  openDialog,
  deleteLoading,
  handleDeleteCandidate,
  candidateDetails,
  toggleDialog,
}: IConfirmDeleteDialog) {
  const { email } = candidateDetails;
  const [emailMatch, setEmailMatch] = useState(false);

  const checkEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMatch(e.target.value.toLowerCase() === email.toLowerCase());
  };

  return (
    <Dialog isOpen={openDialog} aria-labelledby="Delete Candidate">
      <DialogHeading>Delete Candidate Permanently</DialogHeading>
      <DialogBody>
        <P>
          You are about to <Em>permanently</Em> delete this candidate and all PII associated with their user account, this action cannot be undone.
        </P>
        <FormField label="Re-enter their email address to continue" fieldId="reEnterEmailField">
          <TextInput
            placeholder="re-enter email address"
            onChange={checkEmail}
            id="reEnterEmailField"
            autoComplete="off"
          />
        </FormField>
      </DialogBody>
      <DialogSecondaryActions>
        {!deleteLoading && (
          <Button
            look="secondary"
            onClick={() => {
              toggleDialog(false);
            }}
          >
            Cancel
          </Button>
        )}
        {deleteLoading && <Loader alignment="left" label="Deleting candidate" hideLabel={true} />}
      </DialogSecondaryActions>
      <DialogPrimaryActions>
        <Button
          tone="critical"
          onClick={handleDeleteCandidate}
          disabled={deleteLoading || !emailMatch}
        >
          Delete Permanently
        </Button>
      </DialogPrimaryActions>
      <DialogCloseButton
        onClick={() => {
          toggleDialog(false);
        }}
      />
    </Dialog>
  );
}
