import { H1, TitleSection } from '@shortlyster/ui-kit';
import { useState } from 'react';
import SearchBar from '../../components/SearchBar';
import CandidateDetails from './CandidateDetails';
import { useLazyQuery } from 'react-apollo';
import { Flex, Loader, P } from '@compono/ui';
import type { ICandidateDetails, ICandidateQuery } from './types';
import { candidateQuery } from './queries';

export default function CandidatesPage() {
  const [searchInput, setSearchInput] = useState<string>('');
  const [candidateDetails, setCandidateDetails] = useState<ICandidateDetails>(
    {} as ICandidateDetails
  );

  const [getCandidate, { loading, error, data: candidateData }] = useLazyQuery<ICandidateQuery>(
    candidateQuery,
    {
      variables: { email: searchInput },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setCandidateDetails(candidateData?.candidate as ICandidateDetails);
      }
    }
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onSubmit = async () => {
    setCandidateDetails({} as ICandidateDetails);
    if (searchInput) {
      getCandidate({ variables: { email: searchInput } });
    }
  };

  return (
    <>
      <TitleSection>
        <H1>Candidates</H1>
      </TitleSection>
      <small>Search for a candidate</small>
      <SearchBar
        onChange={onChange}
        onSubmit={onSubmit}
        placeholder={'search candidates using email'}
      />

      {loading && (
        <Flex sx={{ flex: 1, alignItems: 'center', marginTop: '50px' }}>
          <Loader alignment={'center'} label={'Loading...'} />
        </Flex>
      )}

      {error && (
        <P sx={{ textAlign: 'center', marginTop: '50px' }}>
          No candidates found with email "{searchInput}"
        </P>
      )}

      {candidateDetails && candidateDetails['email'] ? (
        <CandidateDetails candidateDetails={candidateDetails}></CandidateDetails>
      ) : (
        ''
      )}
    </>
  );
}
