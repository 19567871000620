import { H2 } from '@shortlyster/ui-kit';
import {
  IStagesQuery,
  type IApplication,
  type ICandidateDetailsProps,
  IApplicationStage,
  IDeleteCandidateMutation,
  IContactDetails
} from './types';
import {
  Box,
  Button,
  CoachTip,
  HeaderCell,
  Stack,
  StandardTable,
  StandardTableCellProps,
  StandardTableColumn,
  TableCell,
  TextInput,
} from '@compono/ui';
import { useMutation, useQuery } from 'react-apollo';
import { DetailsList } from './DetailsList';
import { useState } from 'react';
import { deleteCandidateMutation, stagesQuery } from './queries';
import { localeDate, localeTime } from 'src/lib/formattingHelpers';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

const initialApplicationStages = {
  applied: "Applied",
  invited: "Invited",
  unsuccessful: "Unsuccessful",
  hired: "Hired"
};

const ApplicationTableCell = ({ rowData, columnId }: StandardTableCellProps) => (
  <TableCell>{rowData[columnId] || '-'}</TableCell>
);

const CandidateDetails = ({ candidateDetails }: ICandidateDetailsProps) => {
  const hiredApplications = candidateDetails?.applications?.filter(
    application => application.hiredAt && application.hiredForListingId
  );
  const hasCandidateAccount = (
    'contactDetails' in candidateDetails &&
    'phone1' in candidateDetails?.contactDetails
  );
  const hasEmployerAccount = candidateDetails?.accesses?.organisations?.length >= 1;
  const isCandidateHired = hiredApplications.length >= 1;
  const [removedRef, setRemovedRef] = useState('');
  const [applicationStages, setApplicationStages] = useState(initialApplicationStages);
  const [openDialog, setOpenDialog] = useState(false);

  // User does not have a candidate account, exit early
  if (!hasCandidateAccount) {
    return (
      <CoachTip title="No Candidate Account" look="information">
        This user has no candidate account, check the email and try again
      </CoachTip>
    );
  }

  // User has employer access to at least one organisation, exit early
  if (hasEmployerAccount) {
    return (
      <CoachTip title="Employer Account" look="information">
        This account cannot be deleted as it has an employer account with access to at least one organisation
      </CoachTip>
    );
  }

  const {
    contactDetails: { city, state, country, phone1: phone },
    firstName,
    lastName,
    createdAt,
    email,
    id,
    removedAt,
  } = candidateDetails;

  const [deleteCandidate, { loading, error, data: deleteCandidateData }] = useMutation<IDeleteCandidateMutation>(
    deleteCandidateMutation,
    {
      variables: { id, removedRef },
      onCompleted: () => {
        setOpenDialog(false);
      },
      onError: () => {
        setOpenDialog(false);
      }
    }
  );

  useQuery<IStagesQuery>(stagesQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: { applicationStages: IApplicationStage[] }) => {
      let updatedStages: Record<string, string> = { ...initialApplicationStages };
      for (const stage of data.applicationStages) {
        updatedStages[stage.id] = stage.name;
      }
      setApplicationStages(updatedStages);
    }
  });

  const applicationsTableColumns: StandardTableColumn[] = [
    {
      id: 'listing',
      Header: () => HeaderCell({ label: 'Application' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>{rowData[columnId]['title'] || '-'}</TableCell>
      ),
    },
    {
      id: 'createdAt',
      Header: () => HeaderCell({ label: 'Created Date' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>{`${localeDate(rowData[columnId])} ${localeTime(rowData[columnId])}` || '-'}</TableCell>
      ),
    },
    {
      id: 'appliedAt',
      Header: () => HeaderCell({ label: 'Applied Date' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>
          {rowData[columnId] && `${localeDate(rowData[columnId])} ${localeTime(rowData[columnId])}` || '-'}
        </TableCell>
      ),
    },
    {
      id: 'hiredAt',
      Header: () => HeaderCell({ label: 'Hired Date' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>
          {rowData[columnId] && applicationHiredDate(rowData) || '-'}
        </TableCell>
      ),
    },
    {
      id: 'status',
      Header: () => HeaderCell({ label: 'Status' }),
      Cell: ApplicationTableCell,
    },
    {
      id: 'stageId',
      Header: () => HeaderCell({ label: 'Stage' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>{applicationStageName(rowData[columnId], rowData) || '-'}</TableCell>
      ),
    },
    {
      id: 'organisation',
      Header: () => HeaderCell({ label: 'Organisation' }),
      Cell: ({ rowData, columnId }: StandardTableCellProps) => (
        <TableCell>{rowData[columnId].name || '-'}</TableCell>
      ),
    },
  ];

  const handleDeleteCandidate = () => {
    if (removedRef.length > 0) {
      deleteCandidate({ variables: { email, id, removedRef } });
    }
  };

  const applicationHiredDate = (app: IApplication) =>
    app.hiredForListingId === app.listingId ? `${localeDate(app.hiredAt)} ${localeTime(app.hiredAt)}` : '-';

  const applicationStageName = (stageId: string, application: IApplication): string => {
    if (application.hiredForListingId === application.listingId) {
      return applicationStages.hired;
    }
    if (application.unsuccessful) {
      return applicationStages.unsuccessful;
    }
    if (application.stageId) {
      return applicationStages[stageId];
    }
    if (application.appliedAt) {
      return applicationStages.applied;
    }
    if (application.invitedAt) {
      return applicationStages.invited;
    }
    return "-";
  };

  const formatWorkLocation = ({ city, state, country }: Omit<IContactDetails, "phone1">) => {
    return [city, state, country].filter((item) => item).join(', ');
  };

  return (
    <>
      <H2>Candidate Details</H2>
      <Stack gap={2} flat>
        <Box>
          <DetailsList>
            <li>
              <strong>Name:</strong> {firstName} {lastName}
            </li>
            <li>
              <strong>Email:</strong> {email}
            </li>
            <li>
              <strong>Preferred Work Location:</strong> {formatWorkLocation({ city, state, country })}
            </li>
            <li>
              <strong>Phone:</strong> {phone}
            </li>
            <li>
              <strong>Account Created:</strong> {localeDate(createdAt)}
            </li>
          </DetailsList>
        </Box>
        <Box sx={{ justifyContent: 'right' }}>
          <Stack gap={0} flat>
            <TextInput
              placeholder='Jira ticket id'
              onChange={event => {
                event.preventDefault();
                setRemovedRef(event.target.value);
              }}
            ></TextInput>
            <Button
              sx={{ margin: '0 0 0 auto' }}
              disabled={isCandidateHired || !!removedAt || removedRef.length <= 0 || loading}
              onClick={() => {
                setOpenDialog(true);
              }}
              tone='critical'
            >
              Delete Permanently
            </Button>
          </Stack>
        </Box>
      </Stack>

      {isCandidateHired && (
        <CoachTip title="Candidated Hired" look="information">
          This candidate cannot be deleted from the system as they have been hired
        </CoachTip>
      )}

      {removedAt && (
        <CoachTip title="Already Deleted" look="information">
          This candidate has already been deleted, details can be seen in the Jira ticket
        </CoachTip>
      )}

      {error && (
        <CoachTip title="Error" look="critical">
          Unknown error occured while deleting candidate {candidateDetails.email}
        </CoachTip>
      )}

      {deleteCandidateData?.deleteCandidate.status === 'error' && (
        <CoachTip title="Error" look="critical">
          {deleteCandidateData?.deleteCandidate.message}
        </CoachTip>
      )}

      {deleteCandidateData?.deleteCandidate.status === "success" && !loading && (
        <CoachTip title="Candidate Deleted" look="success">
          Candidate with email "{email}" has been deleted
        </CoachTip>
      )}

      {candidateDetails.applications && (
        <Box sx={{ marginTop: '25px' }}>
          <H2>Applications</H2>
          <StandardTable
            data={candidateDetails.applications}
            columns={applicationsTableColumns}
          ></StandardTable>
        </Box>
      )}

      <ConfirmDeleteDialog
        openDialog={openDialog}
        deleteLoading={loading}
        handleDeleteCandidate={handleDeleteCandidate}
        candidateDetails={candidateDetails}
        toggleDialog={setOpenDialog} />
    </>
  );
};

export default CandidateDetails;
