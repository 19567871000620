import gql from 'graphql-tag';

export const deleteCandidateMutation = gql`
  mutation DeleteCandidate($id: ID!, $removedRef: String!) {
    deleteCandidate(id: $id, removedRef: $removedRef) {
      status
      message
    }
  }
`;

export const stagesQuery = gql`
    query FetchApplicationStages {
        applicationStages {
            id
            name
        }
    }
`;

export const candidateQuery = gql`
  query CandidateQuery($email: String!) {
    candidate(email: $email) {
      id
      email
      firstName
      lastName
      timezone
      createdAt
      removedAt
      contactDetails {
        city
        state
        country
        phone1
      }
      accesses {
        genericApps {
          appCode
          createdAt
        }
        organisations {
          organisationId,
          startDate,
          updatedAt,
          organisation {
            name
          }
        }
      }
      applications {
        status
        organisationId
        organisation {
          name
        }
        candidateId
        listingId
        createdAt
        updatedAt
        appliedAt
        invitedAt
        hiredAt
        isActionPending
        isActionPending
        isNew
        unsuccessful
        hiredForListingId
        listing {
          title
        }
        stageId
      }
    }
  }
`;
